import React, { useState, useRef, useEffect, useCallback } from "react";
import Area from "./Area";
import ip from "../../config_ip";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableHeader,
} from "@/components/ui/table";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs.jsx";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import Viewbox from "../UI/Viewbox";
import { Spinner } from "react-bootstrap";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react"
import { Loading } from "../UI/Loading";
import { NoResults } from "../UI/NoResults";

const para = window.location.pathname.split("/");
const jobId = para[2];
const contractor_id = localStorage.getItem("contractor_id");

export default function Areas() {
  
  const [claimPictures, setClaimPictures] = useState([]);
  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);

  const areasByJob = useAllFetch(['areasbyjob'], '/areasbyjob/' + jobId)

  const { getToken } = useAuth()

  const fetchPictures = useCallback(async () => {
    const token = await getToken()
		const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    try {
      const response = await fetch(ip.url + "/getPictures/" + jobId + "/" + 0, options);
      const result = await response.json();
      setClaimPictures(result.data);
    } catch (error) {
      console.error("Error fetching claim pictures:", error);
    }
  }, [getToken]);

  useEffect(() => {
    fetchPictures()
  }, [fetchPictures])
  
  const handleDelete = (deletedFile) => {
    //console.log('Deleting file:', deletedFile);
    setClaimPictures(
      claimPictures.filter((file) => file.filename !== deletedFile.filename)
    );
  };

  const handleFileChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      // console.log("File selected:", file);
      uploadPicture(file, jobId);
    }
  };

  const uploadPicture = async (file) => {
    const formData = new FormData();
    formData.append("picture", file);
    //console.log("Uploading picture:", file);
    const token = await getToken()
    try {
        const response = await fetch(ip.url + "/uploadPicture/" + jobId + '/' + 0, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      console.log(result)
      //console.log(result);
      fetchPictures();
      setLoading(false);
    } catch (error) {
      console.error("Error uploading picture:", error);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  let total_bl = 0
  let total_dh = 0

  if (areasByJob.isPending) {
    return <Loading />
  } else {
    areasByJob.data?.map(areajob => (
      total_bl += areajob.bl
    ))
    areasByJob.data?.map(areajob => (
      total_dh += areajob.dh
    ))
  }

  
  if (areasByJob.data?.length > 0) {
    return (
      <div className="container">
        <div className="font-bold text-2xl mb-2">Areas at {areasByJob.data[0]?.address.split(',')[0]}</div>
        <div className="mb-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>Areas</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        </div>
        <Tabs defaultValue="areas">
          <TabsList>
            <TabsTrigger value="areas">Areas</TabsTrigger>
            <TabsTrigger value="documents">Claim Documents</TabsTrigger>
          </TabsList>
          <TabsContent value="areas">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Time Breakdown</TableHead>
                  <TableHead>Drying Setup</TableHead>
                  <TableHead>Robbie Id</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {areasByJob.data?.map((area, index) => (
                  <Area
                    area={area}
                    // jobandcount={areasByJob.data?.jobandcount} Nose ha hecho push 29-Jun-2024 12:05 pm
                    key={index}
                  />
                ))}
              </TableBody>
              <TableFooter align="left">
                <TableRow>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      Areas: {areasByJob.data?.length}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      Dehumidifiers: {total_dh}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-sm font-normal text-muted-foreground">
                      Air Mover: {total_bl}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TabsContent>
          <TabsContent value="documents">
            {!loading ? (
              <>
                <div className="claim-pictures">
                  {claimPictures.length > 0 ? (
                    //map through claim pictures and display them with the Viewbox component
                    claimPictures.map((file) => (
                      <Viewbox
                        key={file.filename}
                        file={file}
                        jobid={jobId}
                        onDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <NoResults message="No claim pictures available." />
                  )}
                </div>
                <div className="w-full text-center mt-2">
                  <Button onClick={handleClick}>Add Claim Docs/Pics</Button>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  webkitdirectory multiple
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </>
            ) : (
              <div className="flex-div">
                <Spinner animation="border" variant="info" />
                <div>
                  <h3>Loading file ...</h3>
                </div>
              </div>
            )}
          </TabsContent>
        </Tabs>
      </div>
    )
  } else {
    return (
      <div className="container">
        <NoResults message="There are no areas created for this claim." />
      </div>
    )
  }
}
