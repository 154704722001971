import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useAllFetch } from "components/CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import ip from "../../config_ip"
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const jobId = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('contractor_id')
let tokenu = ''

export default function Editjob (props) {

	let { ronly } = props
	const [address2, setAddress2] = useState("")
	const [lat, setLat] = useState("")
	const [lng, setLng] = useState("")
	const [zipCode, setZipCode] = useState("")
	const [province, setProvince] = useState("")
	const { getToken } = useAuth()

	const insurances = useAllFetch(['insurances'], '/insurances')
	const job = useAllFetch(['job'], '/getjob/' + jobId)
	const insuranceid = job.data?.insuranceid
	const adjusters = useAllFetch(['adjusters', job.data?.insuranceid], '/adjusters/' + job.data?.insuranceid)

	const handleChangeA = address => {
		setAddress2(address)
	}

	const handleSelectA = async address => {
		setAddress2(address)
		const results = await geocodeByAddress(address)
		const lenght = results[0].address_components.length
		let zcode = results[0].address_components[results[0].address_components.length-1].long_name.replace(' ','')
		setZipCode(zcode)
		let province1 = (lenght > 3 ? results[0].address_components[lenght - 3].short_name : lenght >= 2 ? results[0].address_components[lenght - 2].short_name : null)
		setProvince(province1)
		const latLng = await getLatLng(results[0])
		setLat(latLng.lat)
		setLng(latLng.lng)
	}
	// Fin 21/Sep/2021
	
	// const getEmailAdjuster = async (padjuster_id) => {
	// 	Allfetch('/getadjuster/' + padjuster_id, setEmailAdjuster, null, null, await getToken())
	// }

	const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/updateJob/' + jobId, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

	const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: async function (json) {
					let addressShort = data.address.split(',')[0]
					addressShort = addressShort.replace('#','')
					// Enviar correo al adjuster y al director. No se ha definido el director.
					await fetch(ip.url + "/send-email/" + jobId + "/0/" + addressShort + "/" + adjusters.data.data?.[0].username + "/R/null", {
						method: "post",
						body: JSON.stringify(data),
						headers: {
							"Accept": "application/json",
							"Content-Type": "application/json",
							"Authorization": `Bearer ${tokenu}`
						},
					})
					if (ronly) {
						let addressShort = data.address.split(',')[0]
						addressShort = addressShort.replace('#','')
						// Enviar correo al adjuster y al director. No se ha definido el director.
						await fetch(ip.url + "/send-email/" + jobId + "/0/" + addressShort + "/" + adjusters.data.data?.[0].username + "/R/null", {
							method: "post",
							body: JSON.stringify(data),
							headers: {
								"Accept": "application/json",
								"Content-Type": "application/json",
								"Authorization": `Bearer ${tokenu}`
							},
						})
						window.location.pathname = "/certificate2/" + jobId;
					} else {
						window.location.pathname = "/jobs/" + contractor_id
					}
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
	};
	
	const searchOptions = {
			componentRestrictions: {country: ["ca","us"]}
	}

	const regexp = new RegExp('^([0-9]{4})([a-zA-Z]{1})([0-9]{4})||([0-9]{10})$', 'i')

	const editJobSchema = object().shape({
			description: string().required(),
			causeloss: string().max(500, "Too Long!").required(),
			address: string().required(),
			postalcode: string().required(),
			insuranceid: number().required(),
			claimnumber: ronly ? job.data?.insuranceid === 7 ? string().min(9).max(10).matches(regexp) : string().min(4) : null,
			adjuster_id: ronly ? number().moreThan(0) : number(),
	})

	const form = useForm({
		resolver: yupResolver(editJobSchema),
		values: {
			address: address2 ? address2 : job.data?.address,
			description: job.data?.description,
			postalcode: zipCode ? zipCode : job.data?.postalcode,
			claimnumber: job.data?.claimnumber === '0' ? '' : job.data?.claimnumber,
			insuranceid: job.data?.insuranceid.toString(),
			contractor_id: job.data?.contractor_id.toString(),
			lat: lat ? lat : job.data?.lat,
			lng: lng ? lng : job.data?.lng,
			adjuster_id: job.data?.adjuster_id.toString(),
			user: job.data?.user,
			causeloss: job.data?.causeloss,
			province: province ? province : job.data?.province,
			id: job.data?.id.toString(),
		}
	})

	if (job.isPending || insurances.isPending || adjusters.isPending) {
		return <Loading />;
	}

	return (
		<div className="container">
      <div className="mb-3">
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>Edit Claim</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
      </div>

			{/* Creado el 21/Sep/2021 */}
			{(ronly === false || ronly === undefined) &&
				<PlacesAutocomplete
					value = {address2}
					onChange = {handleChangeA}
					onSelect = {handleSelectA}
					searchOptions={searchOptions}
				>
					{({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
						<div>
							<input
								{...getInputProps({
									placeholder: 'Search Address ...',
									className: 'location-search-input'
								})}
								autoFocus={true}
							/>
							<div className = "autocomplete-dropdown-container">
								{loading && <div>Loading ...</div>}
								{suggestions.map(suggestion => {
								const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item'
								const style = suggestion.active ? {backgroundColor: '#65bdb1', cursor: 'pointer'} : {backgroundColor: '#ffffff', cursor: 'pointer'}
								return (
									<div {...getSuggestionItemProps(suggestion, {className, style})} key={suggestion.index}>
										<span>{suggestion.description}</span>
									</div>
								)
								})}
							</div>
						</div>  
					)}
				</PlacesAutocomplete>
			}
			{/* Fin 21/Sep/2021 */}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormField
						control={form.control}
						name="address"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Address</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Address"
										{...field}
										{...form.register("address")}
										value={field.value}
										onChange={field.onCHange}
										readOnly={true}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="description"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Building Description</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter building description"
										{...field}
										{...form.register("description")}
										value={field.value}
										onChange={field.onChange}
										readOnly={ronly ? true : false}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="causeloss"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Cause of loss</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter cause of loss"
										{...field}
										{...form.register("causeloss")}
										value={field.value}
										onChange={field.onChange}
										readOnly={ronly ? true : false}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="postalcode"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Postal Code</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Postal Code"
										{...field}
										{...form.register("postalcode")}
										value={field.value}
										onChange={field.onChange}
										readOnly={ronly ? true : false}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField control={form.control}
						name="insuranceid"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Insurances</FormLabel>
								<FormControl>
									<Select
										onValueChange={field.onChange}
										value={field.value}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select Insurance name' />
										</SelectTrigger>
										<SelectContent>
											{insurances.data?.map((insurance) => (
												<SelectItem
													key={insurance.id}
													value={insurance.id.toString()}
												>
													{insurance.insurancename}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)}
					/>
					{ronly && 
						<FormField
							control={form.control}
							name="claimnumber"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Claim Number</FormLabel>
									<FormControl>
										<Input
											placeholder={insuranceid === 7 ? "Enter Claim Number NNNNXNNNN or NNNNNNNNNN" : "Enter Claim Number"}
											{...field}
											{...form.register("claimnumber")}
											value={field.value}
											onChange={field.onChange}
										/>
									</FormControl>
								</FormItem>
							)}
						/>
					}
					{ronly &&
						<FormField control={form.control}
							name="adjuster_id"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Adjusters</FormLabel>
									<FormControl>
										<Select
											value={field.value}
											onValueChange={field.onChange}
										>
											<SelectTrigger>
												<SelectValue placeholder='Select Adjuster name' />
											</SelectTrigger>
											<SelectContent>
												{adjusters.data?.data.map((adjuster) => (
													<SelectItem
														key={adjuster.id}
														value={adjuster.id.toString()}
													>
														{adjuster.aname}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</FormControl>
								</FormItem>
							)}
						/>
					}
					<Button className="mt-2" type="submit">Save</Button>
				</form>
			</Form>
		</div>
	)
}
