import React from "react";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormItem,
} from "@/components/ui/form.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx"
import { FaSave } from "react-icons/fa";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ip from "../../config_ip";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

const contractor_id = localStorage.getItem('temp')

export default function EquipmentForm () {

  const { getToken } = useAuth()

  const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/addEquipment', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lEquipments'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  };

  const equipmentFormSchema = object().shape({
    name: string(),
    type: string(),
    hourly_cad: string(),
  })
  .required()

  const form = useForm({
    resolver: yupResolver(equipmentFormSchema),
    defaultValues: {
      name: "",
      type: "",
      hourly_cad: "",
    },
    values: {
      name: '',
      type: '',
      hourly_cad: '',
    },
  })

  return (
    <div className="container">
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={"/lequipments"}>List Equipments</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>Create Equipment</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Equipment Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Equipment Name"
                    {...field}
                    {...form.register("name")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Equipment Type</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Equipment Type"
                    {...field}
                    {...form.register("type")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="hourly_cad"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Hour Cad</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Hour Cad"
                    {...field}
                    {...form.register("hourly_cad")}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit">
            <FaSave></FaSave>
          </Button>
        </form>
      </Form>
    </div>
  )
}
