import React, { useState, useEffect, Fragment } from 'react'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@/components/ui/table.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { Button } from "@/components/ui/button.jsx";
import { Input } from "@/components/ui/input.jsx";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "lucide-react";
import Allfetch from '../Allfetch';
import { useAuth } from "@clerk/clerk-react";

import { Loading } from "../UI/Loading";

const contractor_id = localStorage.getItem('temp')

export default function Actionlogs() {

  const [startDate, setStartDate] = useState(new Date('1 march 2021'))
  const [finalDate, setFinalDate] = useState(new Date())
  const [currentRegisters, setCurrentRegisters] = useState([])
  const [allRegisters, setAllRegisters] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [status, setStatus] = useState('A')
  const [search1, setSearch1] = useState('')
  const [loading, setLoading] = useState(true)
  const { getToken } = useAuth()
  const itemsPerPage = 8;

  useEffect(() => {
    async function getData() {
      Allfetch('/getactions',
        (data) => {
          setAllRegisters(data);
          setTotalRecords(data?.length);
          setCurrentRegisters(data?.slice(0, itemsPerPage));
        },
        null,
        null,
        await getToken()
      )
    }
    getData()
    setLoading(false)
  }, [getToken])

  const handleFinalDateChange = async (date) => {
    setFinalDate(date)
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const startDay = startDate.getDate();
    const startDateJoin = startYear + "-" + startMonth + "-" + startDay
    const finalMonth = date.getMonth() + 1;
    const finalYear = date.getFullYear();
    const finalDay = date.getDate();
    const finalDateJoin = finalYear + "-" + finalMonth + "-" + finalDay
    Allfetch(
      "/getactionsdate/" + startDateJoin + '/' + finalDateJoin,
      (data) => {
        setAllRegisters(data);
        setTotalRecords(data?.length);
        setCurrentRegisters(data?.slice(0, itemsPerPage));
      },
      null,
      null,
      await getToken()
    );
  }

  const onPageChanged = (newPage) => {
    const offset = (newPage - 1) * itemsPerPage;
    const newCurrentRegisters = allRegisters.slice(
      offset,
      offset + itemsPerPage
    );
    setCurrentRegisters(newCurrentRegisters);
    setCurrentPage(newPage);
  };

  const filterElements = (search1) => {
    var search2 = allRegisters.filter((item) =>
      item.nametable.toLowerCase().includes(search1.toLowerCase()) |
      item.idtable.toString().includes(search1) |
      item.userid.toLowerCase().includes(search1.toLowerCase())
    )
    setTotalRecords(search2.length)
    setCurrentRegisters(search2)
  }

  const onChange = async e => {
    e.persist()
    if (e.target.name === "filter") {
      setStatus(e.target.value)
      setAllRegisters([])
      setCurrentRegisters([])
      Allfetch(
        "/getactionaction/" + e.target.value,
        (data) => {
          setAllRegisters(data);
          setTotalRecords(data?.length);
          setCurrentRegisters(data?.slice(0, itemsPerPage));
        },
        null,
        null,
        await getToken()
      );
      if (status !== 'A') {
        setStatus('A')
      }
    } else if (e.target.name === "search") {
      setSearch1(e.target.value)
      filterElements(e.target.value)
    }
  }

  const handleValueChange = async (newValue) => {
    setStatus(newValue);
    setAllRegisters([]);
    setCurrentRegisters([]);
    Allfetch(
      "/getactionaction/" + newValue,
      (data) => {
        setAllRegisters(data);
        setTotalRecords(data?.length);
        setCurrentRegisters(data?.slice(0, itemsPerPage));
      },
      null,
      null,
      await getToken()
    );
    // You can perform additional actions here based on the selected value if needed
  };

  const totalReg = totalRecords
  const totalPageCount = Math.ceil(totalRecords / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const getPageNumbers = () => {
    const totalPageCount = Math.ceil(totalRecords / itemsPerPage);
    const windowSize = 5; // Total visible pages in the window
    const halfWindow = Math.floor(windowSize / 2);

    let startPage = Math.max(1, currentPage - halfWindow);
    let endPage = Math.min(totalPageCount, currentPage + halfWindow);

    if (currentPage <= halfWindow) {
      endPage = Math.min(windowSize, totalPageCount);
    } else if (currentPage + halfWindow > totalPageCount) {
      startPage = Math.max(1, totalPageCount - windowSize + 1);
    }

    const pages = [];
    // Conditionally add an ellipsis at the start
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push("ellipsis");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Conditionally add an ellipsis at the end
    if (endPage < totalPageCount) {
      if (endPage < totalPageCount - 1) pages.push("ellipsis");
      pages.push(totalPageCount);
    }

    return pages;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='container'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Action Logs</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex flex-row space-x-4 ">
        <div className="basis-auto">
          <Select
            onValueChange={handleValueChange}
            defaultValue="A"
            className="w-[180px]"
          >
            <SelectTrigger>
              <SelectValue placeholder="Set All" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="A">All</SelectItem>
              <SelectItem value="C">Created</SelectItem>
              <SelectItem value="U">Updated</SelectItem>
              <SelectItem value="D">Deleted</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="basis-auto">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[280px] justify-start text-left font-normal",
                  !startDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={startDate}
                onSelect={(date) => setStartDate(date)}
                initialFocus
                disabled={(date) =>
                  date > new Date() || date < new Date("1 march 2021")
                }
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="basis-auto">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[280px] justify-start text-left font-normal",
                  !finalDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {finalDate ? (
                  format(finalDate, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={finalDate}
                onSelect={handleFinalDateChange}
                initialFocus
                disabled={(date) => date > new Date() || date < startDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="basis-1/2">
          <Input
            placeholder="Search by Address, Certificate, Description or Date (YYYY-MM-DD)"
            name="search"
            value={search1}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='container'>
        {currentRegisters?.length > 0 &&
          <Table width="100%" align="center">
            <TableHeader align="left">
              <TableRow>
                {/* <TableHead className='w-1/12' >Id</TableHead> */}
                {/* <TableHead className='w-1/12'>Action</TableHead> */}
                <TableHead className='w-1/12'>What/Where</TableHead>
                {/* <TableHead className='w-1/12'>Register</TableHead> */}
                <TableHead className='w-1/6'>Who/When</TableHead>
                <TableHead className='w-4/6'>Field</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody align="left">
              {currentRegisters?.map((action) => (
                <Fragment key={action.id}>
                  <TableRow className={`font-semibold p-1`}>
                    {/* <TableCell>{action.id}</TableCell> */}
                    {/* <TableCell>{action.action}</TableCell> */}
                    <TableCell>
                      <div className={`font-bold ${action.action === 'C' ? 'text-green-600' : action.action === 'U' ? 'text-yellow-400' : 'text-red-600'}` }>

                        {`${action.action}`}
                      </div>
                      {`${action.nametable}[${action.idtable}]`}
                    </TableCell>
                    {/* <TableCell>{action.idtable}</TableCell> */}
                    <TableCell>
                      <div>
                        {action.userid}
                      </div>
                      <div>
                        {action.dateaction.substring(0, 10) + " " + action.dateaction.substring(11, 16)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {action.curcontfield}
                      </div>
                      <div className='text-muted-foreground'>
                        {action.changedfield}
                      </div>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        }
        {totalReg > itemsPerPage && (
          <Pagination className="justify-end pb-10">
            <PaginationContent>
              {currentPage > 1 && (
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() =>
                      onPageChanged(Math.max(1, currentPage - 1))
                    }
                    disabled={currentPage === 1}
                  />
                </PaginationItem>
              )}

              {getPageNumbers().map((page, index) =>
                page === "ellipsis" ? (
                  <PaginationItem key={"ellipsis-" + index}>
                    <PaginationEllipsis />
                  </PaginationItem>
                ) : (
                  <PaginationItem key={page}>
                    <PaginationLink
                      onClick={() => onPageChanged(page)}
                      className={currentPage === page ? "font-extrabold" : ""}
                    >
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                )
              )}

              {currentPage < totalPageCount && (
                <PaginationItem>
                  <PaginationNext
                    onClick={() =>
                      onPageChanged(Math.min(totalPageCount, currentPage + 1))
                    }
                    disabled={currentPage === totalPageCount}
                  />
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )}
      </div>
    </div>
  )
}

