import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number } from "yup";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import ip from "../../config_ip";
import Statedata from "../Statedata";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

const contractor_id = localStorage.getItem('contractor_id')
const nameuser = localStorage.getItem('name')
let tokenu = ''

export default function JobForm() {

  const [addressSelected, setAddressSelected] = useState(false);
  const [address2, setAddress2] = useState("")
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [zipCode, setZipCode] = useState("")
  const [province, setProvince] = useState('')
  const [save, setSave] = useState(false)
  const { getToken } = useAuth()

  const assigninsucont = useAllFetch(['assigninsucont'], '/assigninsucont/' + contractor_id)

  const handleChangeA = address => {
    setAddress2(address)
  }

  const handleSelectA = async address => {
    setAddress2(address)
    setAddressSelected(true); // Address has been selected
    const results = await geocodeByAddress(address)
    const lenght = results[0].address_components.length
    let zcode = results[0].address_components[results[0].address_components.length - 1].long_name.replace(' ', '')
    setZipCode(zcode)
    let province = (lenght > 3 ? results[0].address_components[lenght - 3].short_name : lenght >= 2 ? results[0].address_components[lenght - 2].short_name : null)
    setProvince(province)
    const latLng = await getLatLng(results[0])
    setLat(latLng.lat)
    setLng(latLng.lng)
  }

  const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/addJob', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )
  
  const onSubmit = async (data) => {
    if (!addressSelected) { // Check if an address has been selected
      alert('Please select an address from the dropdown.');
      return;
    }
    mutation.mutate(data,
      {
        onSuccess: async function (json) {
          await fetch(ip.url + "/send-email/" + json.insertId + "/0/" + address2 + "/null/C/null",
            { method: "get",
              headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": `Bearer ${tokenu}`
              }
            }
          )
          setSave(true)
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const searchOptions = {
    componentRestrictions: { country: ["ca", "us"] }
  }

  const jobSchema = object().shape({
    address: string(),
    postalcode: string(),
    description: string()
      .min(10, "Too Long!")
      .max(50, "Too Long!"),
    insuranceid: number(),
    causeloss: string()
      .min(4, "Too Short!")
      .max(100, "Too Long!"),
    })
    .required()

  const form = useForm({
    resolver: yupResolver(jobSchema),
    values: {
      description: '',
      address: address2,
      postalcode: zipCode,
      claimnumber: 0,
      insuranceid: '',
      contractor_id: contractor_id,
      lat: lat,
      lng: lng,
      adjuster_id: 10,
      user: nameuser,
      causeloss: '',
      province: province,
    }
  })

  if (assigninsucont.isLoading) {
    return <p>Loading</p>
  }

  return (
    <div className="container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Create Claim</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <PlacesAutocomplete
                      value={address2}
                      onChange={handleChangeA}
                      onSelect={handleSelectA}
                      searchOptions={searchOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search Address ...',
                              className: 'location-search-input form-input',
                            })}
                            autoComplete='nope'
                            autoFocus={true}
                          />

                          <div className="suggestions">
                            {loading && <div className="suggestions-loading">Loading ...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion suggestion-item-active'
                                : 'suggestion suggestion-item';
                              return (
                                <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.index}>
                                  {suggestion.description}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postalcode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Postal Code</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={zipCode ? zipCode : "Please select an address from the previous field."}
                      {...field}
                      {...form.register("postalcode")}
                      readOnly
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Building Description</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Write a building description."
                      {...field}
                      {...form.register("description")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="causeloss"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Cause of loss</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Write a cause of loss."
                      {...field}
                      {...form.register("causeloss")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="insuranceid"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Insurance Company</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select an insurance company" />
                      </SelectTrigger>
                      <SelectContent>
                        {assigninsucont.data.map((insurance) => (
                          <SelectItem
                            key={insurance.id}
                            value={insurance.id.toString()}   
                          >
                            {insurance.insurancename}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" disabled={form.formState.isSubmitSuccessful}>Create Claim</Button>
          </form>
        </Form>
      {mutation.isSuccess &&
        <Statedata
          status={save}
          changeStatus={setSave}
          path={"/jobs/" + contractor_id}
          message={'Created successful'}
        />
      }
    </div>
  )
}
