import React from "react";
import PropTypes from "prop-types";

export const Telemetrics = (props) => {
  const {
    current_humidity,
    current_temperature,
    current_dewpoint,
    current_amperage,
    current_power,
    current_energy,
    timeelapsed,
    last_date_updated,
  } = props;

  return (
    <div className="my-3">
      <div className="flex flex-row gap-5">
        <div className="flex gap-2">
          <div>🌵</div>
          <p>{current_humidity} <strong>% Relative Humidity</strong>   </p>
        </div>

        <div className="flex gap-2">
          <div>🌡️</div>
          <p>{current_temperature} <strong>ºC</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🌧️</div>
          <p>{current_dewpoint} <strong>ºC - Dew Point</strong> </p>
        </div>
      </div>

      <div className="flex flex-row gap-5 mb-2">
        <div className="flex gap-2">
          <div>⚡️</div>
          <p> {current_amperage} <strong>Amps</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🦾</div>
          <p> {(current_power / 1000).toFixed(3)} <strong>kW</strong> </p>
        </div>

        <div className="flex gap-2">
          <div>🔋</div>
          <p> {current_energy} <strong>kWh</strong></p>
        </div>
      </div>

      <div className="flex flex-row gap-5">
        <div className="flex gap-5">
          <p className="text-muted-foreground italic text-sm"> <strong>Time:</strong> {timeelapsed} </p>
          <p className="text-muted-foreground italic text-sm"> <strong>Last:</strong> {last_date_updated} </p>
        </div>
      </div>
    </div>
  );
};

Telemetrics.propTypes = {
  current_humidity: PropTypes.number,
  current_temperature: PropTypes.number,
  current_dewpoint: PropTypes.number,
  current_amperage: PropTypes.number,
  current_power: PropTypes.number,
  current_energy: PropTypes.number,
  timeelapsed: PropTypes.string,
  //last_date_updated: PropTypes.instanceOf(Date),
  last_date_updated: PropTypes.string,
};

Telemetrics.defaultParams = {};

export default Telemetrics;
