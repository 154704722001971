import React, { useEffect, useCallback, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import "./styles.scss";
import { useAllFetch } from '../CustomHooks/useAllFetch';
import { Popup } from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoicm9iYmllcmVzdG9yYXRpb250ZWNoIiwiYSI6ImNrdWRrNGhxcDFiNm8ycW84OWI4Mzk4bzQifQ.T16zBOV3lix2HabGj65Mug';

let contractor_id ;
let status;

if (window.location.pathname.includes('/public_map')) {
  contractor_id = '1';
  status = 'F';
} else {
  contractor_id = window.location.pathname.split('/')[2];
  status = window.location.pathname.split('/')[3];
}

export default function MapPub (props) {
  
  const mapContainer = useRef('')

  const pointsmap = useAllFetch(['map'], '/mapPub/' + contractor_id + '/' + status)

  const createGeoJSON = (pointsmap) => {
    const geoJSON = {
      type: 'FeatureCollection',
      features: []
    };
    pointsmap.data?.data.forEach((job) => {
      const feature = {
        type: 'Feature',
        properties: {
          address: job.address,
          saved_emmisions_kgco2: job.saved_emmisions_kgco2,
          saved_duration_sec: job.saved_duration_sec,
          saved_diesel_cad: job.saved_diesel_cad,
        },
        geometry: {
          type: 'Point',
          coordinates: [job.lng, job.lat]
        }
      };
      geoJSON.features.push(feature);
    });
    return geoJSON;
  }
  
  const viewMap = useCallback(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/robbierestorationtech/cl1zq3yrj000415pc2f3qzen5',
      center: [-79.38, 43.65],
      zoom: 10,
    })

    map.on('load', () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.addSource('claims', {
        type: 'geojson',
        // Point to GeoJSON data. This example visualizes all M1.0+ claims
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: createGeoJSON(pointsmap),
        cluster: true,
        clusterMaxZoom: 20, // Max zoom to cluster points on
        clusterRadius: 13 // Radius of each cluster when clustering points (defaults to 50)
      })

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'claims',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            100,
            20,
            750,
            30
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'claims',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'claims',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // inspect a cluster on click
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('claims').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
      });

      map.on('click', 'unclustered-point', (e) => {
        const claim = e.features[0]
        new Popup()
        .setHTML(`<b>Address: ${claim.properties.address}</b></br>
                  <b>Saved Emmisions (KgCO2): ${claim.properties.saved_emmisions_kgco2}</b></br>
                  <b>Saved Duration (s): ${claim.properties.saved_duration_sec}</b></br>
                  <b>Saved Diesel Cad ($): ${claim.properties.saved_diesel_cad}</b>`
        )
        .setLngLat(claim.geometry.coordinates)
        .addTo(map)
      })

      map.on('mouseenter', 'unclustered-point', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'unclustered-point', (e) => {
        map.getCanvas().style.cursor = '';
      });

    });
  }, [pointsmap])

  useEffect(() => {
    viewMap()
  }, [viewMap])
  
  return (
    <div>
    <div className="map-container" ref={mapContainer} />
    </div>
  )
}
