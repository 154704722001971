import React from 'react'
import styled from '@emotion/styled'
import { FaRegWindowClose } from 'react-icons/fa'

export default function Statedata({message, status, changeStatus, path, pathmore = null}) {

  return (
    <>
      {status &&
        <Overlay>
          <ContenedorModal>
            <h3>{message}</h3>
            <CloseButton onClick={() => {changeStatus(false); window.location.pathname = path}}><FaRegWindowClose></FaRegWindowClose></CloseButton>
            {pathmore !== null ?
              <OtherRegister onClick={() => {changeStatus(false); window.location.pathname = pathmore}}>Other register</OtherRegister>
            : 
              null
            }
          </ContenedorModal>
        </Overlay>
      }
    </>
  )
}

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContenedorModal = styled.div`
  width: 500px;
  min-height: 100px;
  background: #FFF;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(100,100,100,.2) 0px 7px 29px 0px;
  padding: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: .3s easy all;
  border-radius: 5px;
  color: #1766DC;
  &:hover {
    background: #F2F2F2
  };

`;

const OtherRegister = styled.button`
  position: relative;
  width: auto;
  height: 30px;
  border: none;
  background: green;
  cursor: pointer;
  transition: .3s easy all;
  border-radius: 5px;
  color: #FFF;
  &:hover {
    background: #23F533
  };
`;
