import React from "react";
import ip from "../../config_ip"
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button.jsx";
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.jsx";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaSave } from "react-icons/fa";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

const equipment_id = window.location.pathname.split('/')[2];
const contractor_id = localStorage.getItem('temp')

export default function EditEquipment (props) {

	const { getToken } = useAuth()

	const equipment = useAllFetch(['equipment'], '/getEquipment/' + equipment_id)
	
	const mutation = useMutation({
    mutationFn: async (data) => {
      const tokenu = await getToken()
      const response = await fetch(ip.url + '/updateEquipment/' + equipment_id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )
	
	const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: function (json) {
          window.location.pathname = '/lequipments'
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
	}
	
	const editEquipmentSchema = object().shape({
		name: string(),
		type: string(),
		hourly_cad: number(),
	})

	const form = useForm({
		resolver: yupResolver(editEquipmentSchema),
		values: {
			id: equipment.data?.id,
			name: equipment.data?.name,
			type: equipment.data?.type,
			hourly_cad: equipment.data?.hourly_cad,
		},
	})

	if (equipment.isPending) {
		return <p>Loading</p>
	}

	return (
		<div className="container">
			<Breadcrumb>
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={"/lequipments"}>List Equipments</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbSeparator />
					<BreadcrumbItem>
						<BreadcrumbLink href={window.location.href}>Edit Equipment</BreadcrumbLink>
					</BreadcrumbItem>
				</BreadcrumbList>
			</Breadcrumb>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Equipment Name</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Equipment Name"
										{...field}
										{...form.register("name")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="type"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Equipment Type</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Equipment Name"
										{...field}
										{...form.register("type")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="hourly_cad"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Hour Cad</FormLabel>
								<FormControl>
									<Input
										placeholder="Enter Hour Cad"
										{...field}
										{...form.register("hourly_cad")}
										value={field.value}
									/>
								</FormControl>
							</FormItem>
						)}
					/>
					<Button type="submit">
						<FaSave></FaSave>
					</Button>
				</form>
			</Form>
		</div>
	)
}
