import { useState } from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import  Allfetch from "../Allfetch";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import ip from '../../config_ip';
import { useMutation } from "@tanstack/react-query";

import { Loading } from "../UI/Loading";

const temp = localStorage.getItem("temp");
const contractor_id = localStorage.getItem("contractor_id");
const nameuser = localStorage.getItem('name')
const job_id = window.location.pathname.split('/')[2]
let tokenu = ''

export default function AreaForm() {

  const [statusI, setStatusI] = useState('')

  const { getToken } = useAuth();

  const robbie = useAllFetch(['robbie'], '/robbie/' + temp + '/' + contractor_id)
  const equipments = useAllFetch(['equipments'], '/lequipDhBl')

  const mutation = useMutation({
    mutationFn: async (data) => {
      tokenu = await getToken()
      const response = await fetch(ip.url + '/insertarea', {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenu}`
        },
      })
      return response.json()
      }
    }
  )

  const onSubmit = async (data) => {
    mutation.mutate(data,
      {
        onSuccess: async function (json) {
          Allfetch('/chStatusRobbie/I/' + data.nameraspi, setStatusI, null, null, await getToken()) // Voy aqui, crear ednpoint en apiv3
          console.log(statusI)
          window.location.pathname = '/lareas/'+ job_id
        },
        onError: function () {
            console.log('Request Error')
        }
      }
    )
  }

  const areaSchema = object().shape({
    aname: string().min(3, "Must be greater than 2").max(50, "Must be lesser than 50"),
    description: string().min(4),
    name_raspi: string().required(),
    humisetpoint: number()
      .min(2)
      .max(3)
      .lessThan(60.01, "Must be lesser than 60")
      .moreThan(29.99, "Must be grater than or equal 30"),
    equipment_dh_id: string().required(),
    dh: number()
      .min(1, "Must be greater than or equal 1")
      .max(2, "Must be less than 3")
      .typeError("must be a number")
      .lessThan(3),
    equipment_bl_id: string(),
    blowers: number()
      .min(0, "must be greater than or equal 0")
      .max(5, "Must be less than 6")
      .typeError("must be a number")
      .lessThan(6),
  }).required();
  
  const form = useForm({
    resolver: yupResolver(areaSchema),
    values: {
      aname: '',
      description: '',
      name_raspi: '',
      humisetpoint: 30,
      job_id: job_id,
      equipment_dh_id: '',
      dh: 1,
      equipment_bl_id: (10).toString(),
      blowers: 0,
      sensor: 'N',
      user: nameuser,
    },
  });

  if (robbie.isPending || equipments.isPending) {
    return <Loading />;  }

  return (
    <div className="container">
      <div className="mb-3">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href={"/jobs/" + contractor_id}>Claims</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={window.location.href}>Add Area</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="aname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name area</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter area name"
                    {...field}
                    {...form.register("aname")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter area description"
                    {...field}
                    {...form.register("description")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name_raspi"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Robbie ID</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Robbie Id" />
                    </SelectTrigger>
                    <SelectContent>
                      {robbie.data.data?.map((robbie) => (
                        <SelectItem
                          key={robbie.id}
                          value={robbie.name_raspi}
                        >
                          {robbie.name_raspi}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="humisetpoint"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Setpoint</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter humisetpoint"
                    {...field}
                    {...form.register("humisetpoint")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="equipment_dh_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dehumidifier Type</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Humidifier type" />
                    </SelectTrigger>
                    <SelectContent>
                      {equipments.data?.[0].map((equipment) => (
                        <SelectItem
                          key={equipment.id}
                          value={equipment.id.toString()}
                        >
                          {equipment.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="dh"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dehumidifier Qty</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Dehumidifiers Qty (1-2)"
                    {...field}
                    {...form.register("dh")}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="equipment_bl_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Air Mover Type</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Blower type" />
                    </SelectTrigger>
                    <SelectContent>
                      {equipments.data?.[1].map(
                        (equipment) => (
                          <SelectItem
                            key={equipment.id}
                            value={equipment.id.toString()}
                          >
                            {equipment.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          {form.getFieldState("equipment_bl_id").isDirty && form.getValues("equipment_bl_id") !== 10 &&
            <FormField
              control={form.control}
              name="blowers"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Air Mover Qty</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Blower Qty (0-5)"
                      {...field}
                      {...form.register("blowers")}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          }
          <Button type="submit" disabled={form.formState.isSubmitSuccessful}>Create Area</Button>
        </form>
      </Form>
    </div>
  );
}
